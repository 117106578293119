<template>
  <div class="editAddress address-wrap">
    <div class="address-header">
      <div class="address-title">编辑地址</div>
      <div class="address-subtitle">请填写详细的地址</div>
    </div>

    <div class="address-content">
      <div class="address-row">
        <div class="row-name">收货人</div>
        <input class="row-input" v-model.trim="name" type="text" placeholder="名字" maxlength="30" />
      </div>
      <div class="address-row">
        <div class="row-name">联系电话</div>
        <input class="row-input" v-model.trim="phone" type="tel" placeholder="手机号" maxlength="11" />
      </div>
      <div class="address-row">
        <div class="row-name">选择地区</div>
        <div v-if="areaString === ''" @click="showArea" class="row-areaPlaceholder">
          省、市、区
          <img src="@/assets/images/icon/arrow-right-grey.png" class="row-arrow">
        </div>
        <div v-else @click="showArea" class="row-area">{{areaString}}</div>
      </div>

      <textarea 
        v-model.trim="address"
        class="address-textarea"
        placeholder="请填写详细地址，不少于5个字"
        maxlength="200"></textarea>

      <div class="setDefault">
        <div class="setDefault-txt">设为默认</div>
        <div @click="toggleSwitch" class="switch" :class="{active: defaultChecked}"></div>
      </div>

      <div class="editAddress-btnWrap">
        <div @click="onSave" class="btn">保存地址</div>
        <div
          v-if="isShowDelete"
          @click="showWarn" 
          class="btn btn-red">删除地址</div>
      </div>
    </div>

    <div @click="onCancel" class="btn btn-transparent address-cancle">取消</div>
  </div>

  <van-popup v-model:show="isShowArea" round position="bottom">
    <van-area 
      class="resetVan"
      title="请选择地区" 
      :area-list="areaList"
      :value="areaId"
      @cancel="isShowArea=false"
      @confirm="areaConfirm" />
  </van-popup>

  <warn-modal 
    v-model:isVisible="isShowWarn"
    @confirm="deleteConfirm">
    <div class="warn-txt">确定删除该地址吗？</div>
  </warn-modal>
</template>

<script src='./EditAddress.js'></script>

<style scoped lang="scss" src="./EditAddress.scss"></style>