
import {
  reactive,
  toRefs,
  inject,
  computed,
  watch,
} from "vue"
import { useRouter, useRoute } from 'vue-router'

import { 
  getAddressApi, 
  updateAddressApi,
  deleteAddressApi,
} from "@/api/address"
import { 
  checkAddressForm,
  defaultError,
} from '@/utils/common.js'

import { Area, Popup } from 'vant'
import { areaList } from '@vant/area-data'
import WarnModal from "@/components/WarnModal"

export default {
  name: 'EditAddress',
  components: {
    [Area.name]: Area,
    [Popup.name]: Popup,
    WarnModal,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const toast = inject('toast')
    const loading = inject('loading')

    const state = reactive({
      name: '',
      phone: '',
      areaArr: [],// 省/市/区
      areaId: '',
      address: '',
      defaultChecked: true,
      isShowArea: false,
      isShowDelete: false,
      isShowWarn: false,
    })

    watch(
      () => route.params, 
      async (toParams) => {
        if(route.name !== 'EditAddress') return
        
        loading.show()
        await getAddress(toParams.id)
        loading.hide()
      },
      { immediate: true }
    )
    async function getAddress(id) {
      const res = await getAddressApi()
    
      if(res.code === 0) {
        res.data.map( x => {
          if(Number(x.id) === Number(id)) {
            state.name = x.consignee
            state.phone = x.telephone
            state.areaArr[0] = x.province
            state.areaArr[1] = x.city
            state.areaArr[2] = x.district
            state.address = x.street
            state.defaultChecked = x.isDefault

            Object.keys(areaList.county_list).forEach(countyKey => {
              if(areaList.county_list[countyKey] === x.district) {
                state.areaId = countyKey
              }
            })
          }
        })

        state.isShowDelete = res.data.length > 1
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }
    const areaString = computed(() => state.areaArr.join(' ').trim())

    const showArea = () => {
      state.isShowArea = true
    }
    const areaConfirm = (res) => {
      state.areaArr = []
      res.map( x => {
        if(x && x.name) {
          state.areaArr.push(x.name)
        }
      })

      state.isShowArea = false
    }

    const toggleSwitch = () => {
      if(!state.isShowDelete) {
        // 只有一个地址时，必须勾选默认
        state.defaultChecked = true
        toast({
          duration: 2000,
          message: '必须有一个默认地址'
        })
        return
      }
      state.defaultChecked = !state.defaultChecked
    }

    const onCancel = () => {
      router.go(-1)
    }

    const onSave = async () => {
      const checkFormRes = checkAddressForm({
        name: state.name,
        phone: state.phone,
        areaString: areaString,
        address: state.address,
      })
      if(!checkFormRes.success) {
        toast({
          duration: 1500,
          message: checkFormRes.error
        })
        return
      }
      
      loading.show()
      const res = await updateAddressApi({
        id: route.params.id,
        province: state.areaArr[0],
        city: state.areaArr[1],
        district: state.areaArr[2],
        street: state.address,
        telephone: state.phone,
        consignee: state.name,
        isDefault: state.defaultChecked,
      })
      loading.hide()

      if(res.code === 0) {
        router.go(-1)
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    const showWarn = () => {
      state.isShowWarn = true
    }
    const deleteConfirm = async () => {
      loading.show()
      const res = await deleteAddressApi({
        id: route.params.id,
      })
      loading.hide()
      
      if(res.code === 0) {
        router.go(-1)
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    return {
      ...toRefs(state),
      areaList,
      areaString,
      showArea,
      areaConfirm,
      toggleSwitch,
      onCancel,
      onSave,
      showWarn,
      deleteConfirm,
    }
  },
} 